
import Vue from 'vue';
import { modeFormMixin } from '@/mixins';
import { eModeType, eWeekdays } from '@/enums';
import { getEmptyDetailedAddress, getEmptyTimeslot } from '@/constants';
import { modeFormsAreValid, getCombinedModeFormData } from '@/util-functions/mode-form-utils';
import SitchAddress from '@/components/custom-ui-components/SitchAddress.vue';
import { saveMode } from '@/util-functions/firestore-mode-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { eNumToTextValueArray, getLocationAddress } from '@/util-functions/misc-utils';

interface CalanderEvent {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
}

export default Vue.extend({
  mixins: [modeFormMixin],
  components: {
    SitchAddress,
  },
  data(): {
    weekdays: TextValue[];
    modeForm: Omit<BookingMode, keyof Mode>;
    events: CalanderEvent[];
    closureDatesMenu: boolean;
    startDateMenu: boolean;
    endDateMenu: boolean;
  } {
    return {
      weekdays: eNumToTextValueArray(eWeekdays),
      modeForm: {} as Omit<BookingMode, keyof Mode>,
      events: [],
      closureDatesMenu: false,
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  computed: {
    customFormModesArray(): { docId: string; name: string }[] {
      const modes = Object.values(this.$store.state.modes) as AnyMode[];
      return [{ docId: '', name: t.none }, ...modes.filter((mode: AnyMode) => mode.type === eModeType.customForm)];
    },
  },
  watch: {
    modeForm() {
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.checkForDeletedCustomForms();
    },
    checkForDeletedCustomForms() {
      const preSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.preSubmissionCustomFormModeId);
      const postSubmissionSelectedCustomForm = this.customFormModesArray.find((mode) => mode.docId === this.modeForm.postSubmissionCustomFormModeId);

      // If the custom form we were using is deleted, reset this.
      if (!preSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
      if (!postSubmissionSelectedCustomForm) {
        this.modeForm.preSubmissionCustomFormModeId = '';
      }
    },
    onGetAddressData(addressData: any, place: any) {
      this.modeForm.locationAddress = getLocationAddress(addressData, place, this.modeForm);
    },
    onAddressInputChange(input: string) {
      if (!input) {
        this.modeForm.locationAddress = getEmptyDetailedAddress();
      }
    },
    onFormPopulatedForEdit() {
      this.onTimeSlotChange();
    },
    onTimeSlotChange() {
      const events: CalanderEvent[] = [];
      const min = new Date();
      min.setHours(0, 0, 0, 0);
      min.setDate(min.getDate() - min.getDay());

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: TimeSlot[] = mixinMethods.postDeletionPreviewArray(this.modeForm.availabilityTimeSlots);
      const timeSlots = postDeletionPreviewArray.filter((item) => !item.isHidden);

      timeSlots.forEach((timeSlot) => {
        const timeOfDayStartHours = Number(timeSlot.timeOfDayStart.split(':')[0]);
        const timeOfDayStartMinutes = Number(timeSlot.timeOfDayStart.split(':')[1]);
        const timeOfDayEndHours = Number(timeSlot.timeOfDayEnd.split(':')[0]);
        const timeOfDayEndMinutes = Number(timeSlot.timeOfDayEnd.split(':')[1]);

        timeSlot.daysOfWeek.forEach((weekday) => {
          const numericalDayOfWeek = Object.values(eWeekdays).indexOf(weekday);
          const startOfEvent = new Date(min);
          startOfEvent.setDate(min.getDate() + numericalDayOfWeek);
          startOfEvent.setHours(timeOfDayStartHours, timeOfDayStartMinutes, 0, 0);
          const endOfEvent = new Date(startOfEvent);
          endOfEvent.setHours(timeOfDayEndHours, timeOfDayEndMinutes, 0, 0);
          events.push({
            name: '',
            start: startOfEvent,
            end: endOfEvent,
            color: '#c96200',
            timed: true,
          });
        });
      });

      this.events = events;
    },
    getEventColor(event: CalanderEvent) {
      return event.color;
    },
    onAddTimeSlot() {
      // This limit is to prevent nefarious use.
      const TIMESLOT_MAX = 10;
      if (this.modeForm.availabilityTimeSlots.length > TIMESLOT_MAX) {
        showError(t?.timeSlotMax.supplant([TIMESLOT_MAX]));
        return;
      }
      this.modeForm.availabilityTimeSlots.push(getEmptyTimeslot());
    },
    timeOfDayStartAllowedMinutes(timeSlot: TimeSlot) {
      let maxMinute = 60;

      if (timeSlot.timeOfDayEnd) {
        const startOfDayArrayHours = parseInt(timeSlot.timeOfDayStart.split(':')[0], 10);
        const endOfDayArrayHours = parseInt(timeSlot.timeOfDayEnd.split(':')[0], 10);
        const startAndEndHourIsSame = startOfDayArrayHours === endOfDayArrayHours;
        maxMinute = startAndEndHourIsSame ? parseInt(timeSlot.timeOfDayEnd.split(':')[1], 10) : maxMinute;
      }
      const minutesList = [];
      for (let i = 0; i <= maxMinute; i++) {
        minutesList.push(i);
      }
      return minutesList;
    },
    timeOfDayEndAllowedMinutes(timeSlot: TimeSlot) {
      let minMinute = 0;

      if (timeSlot.timeOfDayStart) {
        const startOfDayArrayHours = parseInt(timeSlot.timeOfDayStart.split(':')[0], 10);
        const endOfDayArrayHours = parseInt(timeSlot.timeOfDayEnd.split(':')[0], 10);
        const startAndEndHourIsSame = startOfDayArrayHours === endOfDayArrayHours;
        minMinute = startAndEndHourIsSame ? parseInt(timeSlot.timeOfDayEnd.split(':')[1], 10) : minMinute;
      }
      const minutesList = [];
      for (let i = minMinute; i <= 60; i++) {
        minutesList.push(i);
      }
      return minutesList;
    },
    timeOfDayStartAllowedHours(timeSlot: TimeSlot) {
      let maxHour = 24;
      if (timeSlot.timeOfDayEnd) {
        maxHour = parseInt(timeSlot.timeOfDayEnd.split(':')[0], 10);
      }
      const hoursList = [];
      for (let i = 0; i <= maxHour; i++) {
        hoursList.push(i);
      }
      return hoursList;
    },
    timeOfDayEndAllowedHours(timeSlot: TimeSlot) {
      let minHour = 0;
      if (timeSlot.timeOfDayStart) {
        minHour = parseInt(timeSlot.timeOfDayStart.split(':')[0], 10);
      }
      const hoursList = [];
      for (let i = minHour; i <= 24; i++) {
        hoursList.push(i);
      }
      return hoursList;
    },
    onRemoveClosureDate(closureDateToRemove: string) {
      this.modeForm.closureDates = this.modeForm.closureDates.filter((date) => date !== closureDateToRemove);
    },
    saveMode() {
      if (!modeFormsAreValid(this)) {
        return;
      }

      const mixinMethods = this as any as ModeMixinMethods;
      const postDeletionPreviewArray: TimeSlot[] = mixinMethods.postDeletionPreviewArray(this.modeForm.availabilityTimeSlots);

      let timeSlotIsInvalid = false;

      postDeletionPreviewArray.forEach((timeSlot: TimeSlot) => {
        const startOfDayArrayHours = parseInt(timeSlot.timeOfDayStart.split(':')[0], 10);
        const startOfDayArrayMins = parseInt(timeSlot.timeOfDayStart.split(':')[1], 10);
        const startOfDayInMins = startOfDayArrayHours * 60 + startOfDayArrayMins;
        const endOfDayArrayHours = parseInt(timeSlot.timeOfDayEnd.split(':')[0], 10);
        const endOfDayArrayMins = parseInt(timeSlot.timeOfDayEnd.split(':')[1], 10);
        const endOfDayInMins = endOfDayArrayHours * 60 + endOfDayArrayMins;
        if (startOfDayInMins > endOfDayInMins) {
          showError(t?.startOfDayCannotBeLaterThanEndOfDay);
          timeSlotIsInvalid = true;
        }
      });

      if (timeSlotIsInvalid) {
        return;
      }

      mixinMethods
        .pruneAndCommitDeletionsJustBeforeCreatingCombinedModeData()
        .then(() => {
          const combinedModeData: BookingMode = getCombinedModeFormData(this);
          saveMode({ ...combinedModeData });
        })
        .catch(() => {
          showError(t.somethingWentWrongWhenDeletingAnItem);
        });
    },
  },
});
